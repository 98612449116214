import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Mixin from 'components/common/Mixin'
import { compose, withProps } from 'recompose'
import { imgLazyLoad } from 'components/common/hoc/withLazyLoader'
import { pickSrc, pickImgSrc } from './helpers'

/* Logo */
import Logo from './assets/Logo.svg'
import BigOTech from './assets/big-o-tech.svg'

/* Arrows */
import ArrowDown from './assets/Arrow-down.svg'
import ArrowDownBlack from './assets/arrow-down-black.svg'
import ArrowDownChartreuse from './assets/arrow-down-chartreuse.svg'
import ArrowDownLightBlue from './assets/Arrow-down-light-blue.svg'
import ArrowUpLightBlue from './assets/Arrow-up-light-blue.svg'
import ArrowRight from './assets/Arrow-right.svg'
import ArrowRightWhite from './assets/Arrow-right-white.svg'
import ArrowRightBrightRed from './assets/Arrow-right-bright-red.svg'
import ArrowLeftBrightRed from './assets/Arrow-left-bright-red.svg'
import ArrowDownBrightRed from './assets/Arrow-down-bright-red.svg'
import ArrowUpBrightRed from './assets/Arrow-up-bright-red.svg'
import ArrowDownWhite from './assets/Arrow-down-white.svg'
import ArrowDownGray from './assets/Arrow-down-gray.svg'
import ArrowUpFill from './assets/Arrow-up-fill.svg'
import ArrowLeft from './assets/Arrow-left.svg'
import ArrowLeftWhite from './assets/Arrow-left-white.svg'
import ArrowUp from './assets/Arrow-up.svg'
import ArrowUpWhite from './assets/Arrow-up-white.svg'
import ArrowUpGray from './assets/Arrow-up-gray.svg'
import ArrowDownFullBlack from './assets/arrow-icon-full-black-down.svg'
import ArrowDownFullWhite from './assets/arrow-icon-full-white-down.svg'
import ArrowUpFullBlack from './assets/arrow-icon-full-black-up.svg'
import ArrowUpFullWhite from './assets/arrow-icon-full-white-up.svg'
import ArrowDownFullRed from './assets/arrow-icon-full-red-down.svg'
import ArrowDownFullBrightRed from './assets/arrow-icon-full-bright-red-down.svg'
import ArrowUpFullRed from './assets/arrow-icon-full-red-up.svg'
import ArrowUpFullBrightRed from './assets/arrow-icon-full-bright-red-up.svg'
import DropdownArrow from './assets/Dropdown-arrow.svg'
import DropdownArrowDisabled from './assets/Dropdown-arrow-disabled.svg'
import CaretRedLeft from './assets/caret-red-left.svg'
import CaretRedRight from './assets/caret-red-right.svg'
import CaretBlueRight from './assets/caret-blue-right.svg'
import ArrowBluRight from './assets/arrow-right-blue.png'
import ArrowBlackLeft from './assets/arrow-left-black.png'
import BigOLogo from './assets/Big-o-logo.jpg'
import FilledUpArrow from './assets/Filled-up-arrow.svg'
import FilledDownArrow from './assets/Filled-down-arrow.svg'

/* Menu */
import MobileMenu from './assets/icon-menu.svg'

/* CarBar */
import NoVehicle from './assets/No-vehicle.png'
import CarExample from './assets/Car-example.jpg'

/* Seasons */
import IconStreet from './assets/icon-street.svg'
import IconTerrain from './assets/icon-terrain.svg'
import AllSeason from './assets/Icon-all-season.svg'
import Winter from './assets/Icon-winter.svg'
import Summer from './assets/icon-summer.svg'

/* Make an Appointment Menu */
import Clock from './assets/Clock.svg'

/* Search */
import Search from './assets/Search.svg'
import SearchBlue from './assets/Search-blue.svg'
import SearchChartreuse from './assets/Search-chartreuse.svg'
import SearchWhite from './assets/Search-white.svg'

/* Profile, Cart and Checkout */
import Cart from './assets/icon-cart.svg'
import CartRed from './assets/icon-cart-red.svg'
import CartBlack from './assets/Cart-Icon-Black.svg'
import CartBig from './assets/Cart-big.png'
import MobileCart from './assets/Cart.svg'
import CheckCircle from './assets/Check-circle.svg'
import Location from './assets/icon-location.svg'
import LocationWhite from './assets/icon-location-white.svg'
import CartRedSecondary from './assets/Cart-Red.svg'
import UserIconRed from './assets/User-icon.svg'

/* Account */
import AddressConfirmArrow from './assets/Address-confirm-arrow.svg'
import IconAccount from './assets/icon-account.svg'
import IconAccountBrightRed from './assets/icon-account-bright-red.svg'
import IconLogout from './assets/icon-logout.svg'
import IconLogoutWhite from './assets/icon-logout-white.svg'

/* Call To Action */
import Chat from './assets/Chat.svg'
import MenuClose from './assets/Menu-close.svg'
import MenuCloseWhite from './assets/Menu-close-white.svg'
import CloseIconRed from './assets/Close-icon-red.svg'
import Phone from './assets/Phone.svg'
import PhoneWhite from './assets/Phone-white.svg'
import PhoneBlack from './assets/Phone-black.svg'
import PhoneBlue from './assets/Phone-blue.svg'
import PhoneRed from './assets/Phone-red.svg'
import Video from './assets/Video.svg'
import Play from './assets/Play.svg'
import Pause from './assets/Pause.svg'
import PlayInCircle from './assets/play-in-circle.svg'
import PauseInCircle from './assets/pause-in-circle.svg'

/* YMME */
import Plate from './assets/Plate.svg'
import CarPlaceholder from './assets/Сar-placeholder.svg'

/* Social */
import Facebook from './assets/Facebook.svg'
import Instagram from './assets/Instagram.svg'
import Twitter from './assets/Twitter.svg'

//
import Surface from './assets/Surface.svg'
import Car from './assets/Car.svg'
import CarIcon from './assets/Car-icon.svg'
import Petal from './assets/Petal.svg'
import Purse from './assets/Purse.svg'
import Calendar from './assets/Calendar.svg'

// @TODO ask UX team to provide them in svg format if it is possible
import MasterCard from './assets/Mastercard.jpg'
import Paypal from './assets/Paypal.jpg'

// BOT icons
import BookAppointmentBlack from './assets/Book-Appointment-Icon-Black.svg'
import BookAppointmentChartreuse from './assets/Book-Appointment-Icon-Chartreuse.svg'
import BookAppointmentRed from './assets/Book-Appointment-Icon-Red.svg'
import BookAppointmentWhite from './assets/Book-Appointment-Icon-White.svg'
import ShopTiresWhite from './assets/Shop-Tires-Icon-White.svg'
import ViewSpecialsWhite from './assets/View-Specials-Icon-White.svg'
import ViewServiceWhite from './assets/View-Services-Icon-White.svg'
import ArrowRightWhiteSecondary from './assets/Btn-chevron-White.svg'
import ArrowRightRedSecondary from './assets/Btn-Chevron-Red-Right.svg'
import ArrowLeftRedSecondary from './assets/Btn-Chevron-Red-Left.svg'
import ArrowRightGraySecondary from './assets/Arrow-Right-Gray-Secondary.svg'
import PlusRedSecondary from './assets/Plus-Red-Secondary.svg'
import PlusBrightRedSecondary from './assets/Plus-Bright-Red-Secondary.svg'
import SearchIcon from './assets/Search-Icon.svg'
import CrossGrey from './assets/cross-grey.svg'
import VehicleIcon from './assets/Vehicle-Icon.svg'
import ShopByVehicle from './assets/Shop-By-Vehicle-Icon.svg'
import ShopByVehicleWhite from './assets/Shop-By-Vehicle-Icon-White.svg'
import ShopByLicensePlate from './assets/Shop-By-Lic-Plate.svg'
import ShopByTireSize from './assets/Shop-By-Tire-Size.svg'
import ShopByTireSizeWhite from './assets/Shop-By-Tire-Size-White.svg'
import ShopByTireSizeRed from './assets/Shop-By-Tire-Size-Red.svg'
import ShopByTireBrand from './assets/Shop-By-Tire-Brand.svg'
import ShopByTireBrandWhite from './assets/Shop-By-Tire-Brand-White.svg'
import CalendarNew from './assets/Calendar-New.svg'
import CalendarNewWhite from './assets/Calendar-New-White.svg'
import Tooltip from './assets/Tooltip.svg'
import TooltipActive from './assets/Tooltip-active.svg'
import TooltipWhite from './assets/Tooltip-white.svg'
import TooltipWhiteActive from './assets/Tooltip-white-active.svg'

import Alert from './assets/Alert.svg'
import AlertOrange from './assets/Alert-orange.svg'
import Success from './assets/Success.svg'
import Denied from './assets/Denied.svg'

import Cross from './assets/Cross.svg'
import CrossWhite from './assets/Cross-white.svg'
import CrossBlue from './assets/Cross-blue.svg'
import CrossRed from './assets/Cross-red.svg'
import CrossBrightRed from './assets/Cross-bright-red.svg'
import Tire from './assets/Tire.svg'
import TireBrightRed from './assets/Tire-bright-red.svg'
import TireWhite from './assets/Tire-white.svg'
import Book from './assets/Book.svg'
import SpecialOffers from './assets/Special-offers.svg'
import Services from './assets/Services.svg'
import ArrowRedRight from './assets/arrow-icon-full-red-right.svg'
import ArrowBrightRedRight from './assets/arrow-icon-full-bright-red-right.svg'
import PlusRed from './assets/Plus-red.svg'
import MinusRed from './assets/Minus-red.svg'
import PlusBlue from './assets/Plus-blue.svg'
import PlusBlueDrawer from './assets/Drawer-plus.svg'
import MinusBlue from './assets/Minus-blue.svg'
import MinusBlueDrawer from './assets/Drawer-minus.svg'
import CardPromo from './assets/Icon-credit-card.svg'
import DiscountPromo from './assets/discount-tag.svg'
import DiscountPromoWhite from './assets/discount-tag-white.svg'
import TirePromoBlue from './assets/Icon-tire-blue.svg'
import SpeedRatio from './assets/Speed-rating-value.svg'
import WarrantyBadge2 from './assets/Warranty-badge-40k.jpg'
import TireProtection from './assets/Tire-protection.svg'
import EmailBlue from './assets/Email-blue.svg'
import PrintBlue from './assets/Print-blue.svg'
import CarBlack from './assets/Car-black.svg'
import CalendarBlack from './assets/Calendar-black.svg'
import LocationBlack from './assets/Location-black.svg'
import ServiceBlack from './assets/Service-black.svg'
import CarRed from './assets/Car-red.svg'
import CalendarRed from './assets/Calendar-red.svg'
import LocationRed from './assets/Location-red.svg'
import DealRed from './assets/Deal-red.svg'
import ContactBlack from './assets/contact-black.svg'
import ContactRed from './assets/contact-red.svg'
import LocationRedBig from './assets/Location-red-big.png'
import LocationBlue from './assets/Location-blue.svg'
import ServiceRed from './assets/Service-red.svg'
import IconVecicle from './assets/Car-image.svg'
import IconTrailer from './assets/Trailer-image.svg'
import IconAlignment from './assets/Icon-alignment.svg'
import IconBattery from './assets/Icon-battery.svg'
import IconBrakes from './assets/Icon-brakes.svg'
import IconOil from './assets/Icon-oil.svg'
import IconRepair from './assets/Icon-repair.svg'
import IconRoutine from './assets/Icon-routine.svg'
import IconSuspension from './assets/Icon-suspension.svg'
import AlignmentRed from './assets/Alignment-red.svg'
import BatteryRed from './assets/Battery-red.svg'
import BrakesRed from './assets/Brakes-red.svg'
import OilRed from './assets/Oil-red.svg'
import RepairRed from './assets/Repair-red.svg'
import RoutineRed from './assets/Routine-red.svg'
import SuspensionRed from './assets/Suspension-red.svg'
import PopularRed from './assets/Popular-red.svg'
import ArrowDatepickerRight from './assets/Icon-arrow-white.png'
import ArrowDatepickerLeft from './assets/Icon-arrow-white-reversed.png'
import ArrowRedDown from './assets/Icon-arrow-red.png'
import ArrowGrayUp from './assets/Icon-arrow-gray.png'
import ArrowDownCommonRed from './assets/Arrow-down-common-red.svg'
import ArrowRedUp from './assets/Icon-arrow-red-up.png'
import ArrowGrayDown from './assets/Icon-arrow-gray-down.png'
import IconEdit from './assets/Icon-edit.png'
import CheckedGreen from './assets/Checked-green.png'
import CheckGray from './assets/check-gray.svg'
import CheckWhite from './assets/check-white.svg'
import CaretBlueDown from './assets/Icon-caret-blue.png'
import IconFilter from './assets/Icon-filter.png'
import IconFilterWhite from './assets/Icon-filter-white.png'
import IconCircleClose from './assets/Icon-circle-close.png'
import IconSearch from './assets/Icon-search.png'
import TreadLifeWarrantyBadge from './assets/Tread-life-warranty-badge.svg'
import ManufacturesLimitedWarrantyBadge from './assets/Manufactures-limited-warranty-badge.svg'
import DiamondGreen from './assets/Diamond-green.svg'
import ScrollDown from './assets/Scroll-down.svg'

import IconRuleError from './assets/icon-rule-error.svg'
import IconRuleSuccess from './assets/icon-rule-success.svg'
import IconRuleUntouched from './assets/icon-rule-default.svg'

import AirFilterChange from './assets/air-filter-change.svg'
import AirFilterChangeRed from './assets/air-filter-change-red.svg'
import AlternatorSteringAndReplacement from './assets/alternator-stering-and-replacement.svg'
import AlternatorSteringAndReplacementRed from './assets/alternator-stering-and-replacement-red.svg'
import BallJoints from './assets/ball-joints.svg'
import BallJointsRed from './assets/ball-joints-red.svg'
import BatteryCableReplacement from './assets/battery-cable-replacement.svg'
import BatteryCableReplacementRed from './assets/battery-cable-replacement-red.svg'
import BatteryTesting from './assets/battery-testing.svg'
import BatteryTestingRed from './assets/battery-testing-red.svg'
import BeltReplacement from './assets/belt-replacement.svg'
import BeltReplacementRed from './assets/belt-replacement-red.svg'
import BrakeFluidExchange from './assets/brake-fluid-exchange.svg'
import BrakeFluidExchangeRed from './assets/brake-fluid-exchange-red.svg'
import BrakeInspection from './assets/brake-inspection.svg'
import BrakeInspectionRed from './assets/brake-inspection-red.svg'
import BrakeRepair from './assets/brake-repair.svg'
import BrakeRepairRed from './assets/brake-repair-red.svg'
import CvJointsAndCvAxles from './assets/cv-joints-and-cv-axles.svg'
import CvJointsAndCvAxlesRed from './assets/cv-joints-and-cv-axles-red.svg'
import CabinFilterChange from './assets/cabin-filter-change.svg'
import CabinFilterChangeRed from './assets/cabin-filter-change-red.svg'
import CoolingSteringFlushAndFill from './assets/cooling-stering-flush-and-fill.svg'
import CoolingSteringFlushAndFillRed from './assets/cooling-stering-flush-and-fill-red.svg'
import DifferentialFluidExchange from './assets/differential-fluid-exchange.svg'
import DifferentialFluidExchangeRed from './assets/differential-fluid-exchange-red.svg'
import FuelFilterChange from './assets/fuel-filter-change.svg'
import FuelFilterChangeRed from './assets/fuel-filter-change-red.svg'
import FuelSystemCleaning from './assets/fuel-system-cleaning.svg'
import FuelSystemCleaningRed from './assets/fuel-system-cleaning-red.svg'
import HeadlightsAndTaillights from './assets/headlights-and-taillights.svg'
import HeadlightsAndTaillightsRed from './assets/headlights-and-taillights-red.svg'
import InstallInterstateBattery from './assets/install-interstate-battery.svg'
import InstallInterstateBatteryRed from './assets/install-interstate-battery-red.svg'
import InstallInterstateMegaTronIiBattery from './assets/install-interstate-mega-tron-ii-battery.svg'
import InstallInterstateMegaTronIiBatteryRed from './assets/install-interstate-mega-tron-ii-battery-red.svg'
import InstallInterstatePowerfastBattery from './assets/install-interstate-powerfast-battery.svg'
import InstallInterstatePowerfastBatteryRed from './assets/install-interstate-powerfast-battery-red.svg'
import OilChange from './assets/oil-change.svg'
import OilChangeRed from './assets/oil-change-red.svg'
import PowerSteringFluidExchange from './assets/power-stering-fluid-exchange.svg'
import PowerSteringFluidExchangeRed from './assets/power-stering-fluid-exchange-red.svg'
import RackAndPinion from './assets/rack-and-pinion.svg'
import RackAndPinionRed from './assets/rack-and-pinion-red.svg'
import RadiantAndCoolantHoses from './assets/radiant-and-coolant-hoses.svg'
import RadiantAndCoolantHosesRed from './assets/radiant-and-coolant-hoses-red.svg'
import ShocksShocks from './assets/shocks-shocks.svg'
import ShocksShocksRed from './assets/shocks-shocks-red.svg'
import SnowTireInstallation from './assets/snow-tire-installation.svg'
import SnowTireInstallationRed from './assets/snow-tire-installation-red.svg'
import SpecialOrder from './assets/special-order.svg'
import StarterSteringAndReplacement from './assets/starter-stering-and-replacement.svg'
import StarterSteringAndReplacementRed from './assets/starter-stering-and-replacement-red.svg'
import SteeringSteringSuspensionEvaluation from './assets/steering-stering-suspension-evaluation.svg'
import SteeringSteringSuspensionEvaluationRed from './assets/steering-stering-suspension-evaluation-red.svg'
import StrutsStruts from './assets/struts-struts.svg'
import StrutsStrutsRed from './assets/struts-struts-red.svg'
import TieRods from './assets/tie-rods.svg'
import TieRodsRed from './assets/tie-rods-red.svg'
import TireMounting from './assets/tire-mounting.svg'
import TireMountingRed from './assets/tire-mounting-red.svg'
import TirePressureCheck from './assets/tire-pressure-check.svg'
import TirePressureCheckRed from './assets/tire-pressure-check-red.svg'
import TireRepair from './assets/tire-repair.svg'
import TireRepairRed from './assets/tire-repair-red.svg'
import TireRotation from './assets/tire-rotation.svg'
import TireRotationRed from './assets/tire-rotation-red.svg'
import TransmissionFluidExchange from './assets/transmission-fluid-exchange.svg'
import TransmissionFluidExchangeRed from './assets/transmission-fluid-exchange-red.svg'
import UsedTires from './assets/used-tires.svg'
import UsedTiresRed from './assets/used-tires-red.svg'
import WheelAlignment from './assets/wheel-alignment.svg'
import WheelAlignmentRed from './assets/wheel-alignment-red.svg'
import WheelBalancing from './assets/wheel-balancing.svg'
import WheelBalancingRed from './assets/wheel-balancing-red.svg'
import WiperBlades from './assets/wiper-blades.svg'
import WiperBladesRed from './assets/wiper-blades-red.svg'
import TirePressureMonitoringSystem from './assets/TirePressureMonitoringSystem.svg'
import IconInfo from './assets/Icon-info.svg'
import EllipsisRed from './assets/Ellipsis-red.svg'
import FeedbackRed from './assets/FeedBack-red.svg'
import AccessibilityRed from './assets/Accessibility-red.svg'
import IconInfoBlueRegular from './assets/Icon-info-blue-regular.svg'
import IconInfoBlueSolid from './assets/Icon-info-blue-solid.svg'

import HeartBlack from './assets/heart-black.svg'
import HeartWhite from './assets/heart-white.svg'
import HeartRed from './assets/heart-red.svg'
import HeartRedFilled from './assets/heart-red-filled.svg'
import ShareLinkDesktop from './assets/share-link-desktop.svg'
import ShareLinkMobile from './assets/share-link-mobile.svg'
import ActiveVehiclePlaceholder from './assets/garage-active-vehicle-placeholder.svg'
import VehiclePlaceholder from './assets/garage-vehicle-placeholder.svg'
import SpeedometerGrey from './assets/speedometer-grey.svg'
import SpeedometerBlue from './assets/speedometer-blue.svg'
import EditGrey from './assets/Edit-Grey.svg'
import EditRed from './assets/Edit-red.svg'
import EllipsisGrey from './assets/Ellipsis-Grey.svg'
import EditBlack from './assets/Edit-Black.svg'
import DeleteBlack from './assets/Delete-Black.svg'
import TireRotationWhite from './assets/tire-rotation-white.svg'
import OilChangeWhite from './assets/oil-change-white.svg'
import TipsAndResources from './assets/tips-and-resources.svg'
import Mileage from './assets/mileage.svg'
import MainEditBlue from './assets/MainEditBlue.svg'
import DeleteBlue from './assets/DeleteBlue.svg'
import DeleteRed from './assets/DeleteRed.svg'
import EditBlue from './assets/EditBlue.svg'
import EditGreen from './assets/EditGreen.svg'
import TireSize from './assets/TireSize.svg'
import License from './assets/License.svg'
import VinNumber from './assets/VinNumber.svg'
import TireRotationSecondary from './assets/TireRotation.svg'
import OilChangeSecondary from './assets/OilChange.svg'
import RecomendationSuccess from './assets/recomendation-success.svg'
import RecomendationMedium from './assets/recomendation-medium.svg'
import RecomendationWarning from './assets/recomendation-warning.svg'
import RecomendationDanger from './assets/recomendation-danger.svg'
import OilChangeEV from './assets/OilChangeEV.svg'
import AlertTriangleRed from './assets/alert-triangle.svg'
import AlertTriangleYellow from './assets/alert-triangle-yellow.svg'
import AlertTriangleBlack from './assets/alert-triangle-black.svg'
import AlertLightRed from './assets/alert-light-red.svg'
import DisclaimerBlue from './assets/disclaimer-blue.svg'
import ServiceHistory from './assets/ServiceHistory.svg'
import InfoIcon from './assets/InfoIcon.svg'
import RepairWhite from './assets/Repair-white.svg'
import AlignmentWhite from './assets/Alignment-white.svg'
import BatteryWhite from './assets/Battery-white.svg'
import BrakesWhite from './assets/Brakes-white.svg'
import OilWhite from './assets/Oil-white.svg'
import RoutineWhite from './assets/Routine-white.svg'
import SuspensionWhite from './assets/Suspension-white.svg'
import CheckmarkBoxFilledGreen from './assets/checkmark-box-filled-green.svg'
import CheckmarkBoxGrey from './assets/checkmark-box-grey.svg'
import VehicleWithNotebook from './assets/vehicle-with-notebook.svg'
import CloudLoading from './assets/cloud-loading.svg'
import FleetCertifiedLogoGold from './assets/fleet-certified-icon-gold.svg'
import FleetCertifiedLogoGreen from './assets/fleet-certified-icon-green.svg'
import Spinner from './assets/Spinner.svg'
// TODO for phase 1 we have only one amenities icon. See https://tbccorp.atlassian.net/browse/DPW-7610
import DefaultAmenity from './assets/default-amenity.png'

import EmptyPage from './assets/Empty-page.svg'

import CartWhite from './assets/Cart-Icon-White.svg'

const map = {
  'oil-lube-and-filter': OilWhite,
  'routine-services-and-fluids': RoutineWhite,
  'suspension-and-front-end': SuspensionWhite,
  'book-appointment-black': BookAppointmentBlack,
  'book-appointment-chartreuse': BookAppointmentChartreuse,
  'book-appointment-red': BookAppointmentRed,
  'book-appointment-white': BookAppointmentWhite,
  'show-tires-white': ShopTiresWhite,
  'view-specials-white': ViewSpecialsWhite,
  'view-services-white': ViewServiceWhite,
  'arrow-right-white-secondary': ArrowRightWhiteSecondary,
  'arrow-right-red-secondary': ArrowRightRedSecondary,
  'arrow-left-red-secondary': ArrowLeftRedSecondary,
  'arrow-right-gray-secondary': ArrowRightGraySecondary,
  'plus-red-secondary': PlusRedSecondary,
  'plus-bright-red-secondary': PlusBrightRedSecondary,
  'search-secondary': SearchIcon,
  'cart-white': CartWhite,
  'cart-black': CartBlack,
  'cross-grey': CrossGrey,
  'shop-by-vehicle': ShopByVehicle,
  'vehicle-icon': VehicleIcon,
  'shop-by-vehicle-white': ShopByVehicleWhite,
  'shop-by-license-plate': ShopByLicensePlate,
  'shop-by-tire-size': ShopByTireSize,
  'shop-by-tire-size-white': ShopByTireSizeWhite,
  'shop-by-tire-size-red': ShopByTireSizeRed,
  'shop-by-tire-brand': ShopByTireBrand,
  'shop-by-tire-brand-white': ShopByTireBrandWhite,
  'calendar-new': CalendarNew,
  'calendar-new-white': CalendarNewWhite,
  tooltip: Tooltip,
  'tooltip-active': TooltipActive,
  'tooltip-white': TooltipWhite,
  'tooltip-white-active': TooltipWhiteActive,
  'tread-life-warranty-Badge': TreadLifeWarrantyBadge,
  'manufactures-limited-warranty-badge': ManufacturesLimitedWarrantyBadge,
  alert: Alert,
  'alert-orange': AlertOrange,
  success: Success,
  denied: Denied,
  cross: Cross,
  'cross-white': CrossWhite,
  'cross-blue': CrossBlue,
  'cross-red': CrossRed,
  'cross-bright-red': CrossBrightRed,
  'icon-menu': MobileMenu,
  cart: Cart,
  'cart-red': CartRed,
  'cart-big': CartBig,
  clock: Clock,
  'cart-mobile': MobileCart,
  'contact-black': ContactBlack,
  'contact-red': ContactRed,
  'arrow-left': ArrowLeft,
  'arrow-left-white': ArrowLeftWhite,
  tire: Tire,
  'tire-bright-red': TireBrightRed,
  'tire-white': TireWhite,
  book: Book,
  plate: Plate,
  'car-placeholder': CarPlaceholder,
  'special-offers': SpecialOffers,
  services: Services,
  'icon-rule-error': IconRuleError,
  'icon-rule-success': IconRuleSuccess,
  'icon-rule-default': IconRuleUntouched,
  'arrow-down-gray': ArrowDownGray,
  'arrow-up-gray': ArrowUpGray,
  'plus-red': PlusRed,
  'minus-red': MinusRed,
  'plus-blue': PlusBlue,
  'plus-blue-drawer': PlusBlueDrawer,
  'minus-blue': MinusBlue,
  'minus-blue-drawer': MinusBlueDrawer,
  'card-promo': CardPromo,
  'tire-promo': DiscountPromo,
  'money-promo': DiscountPromo,
  'tire-promo-white': DiscountPromoWhite,
  'tire-promo-blue': TirePromoBlue,
  'speed-badge': SpeedRatio,
  'warranty-badge-40k': WarrantyBadge2,
  'tire-protection': TireProtection,
  'icon-all-season': AllSeason,
  'icon-winter': Winter,
  'icon-summer': Summer,
  'print-blue': PrintBlue,
  'email-blue': EmailBlue,
  'car-black': CarBlack,
  'car-calendar': CalendarBlack,
  'car-location': LocationBlack,
  'car-service': ServiceBlack,
  'car-red': CarRed,
  'diamond-green': DiamondGreen,
  'calendar-red': CalendarRed,
  'location-red': LocationRed,
  'location-white': LocationWhite,
  'deal-red': DealRed,
  'location-red-big': LocationRedBig,
  'location-blue': LocationBlue,
  'service-red': ServiceRed,
  'icon-vehicle': IconVecicle,
  'icon-trailer': IconTrailer,
  'icon-alignment': IconAlignment,
  'icon-battery': IconBattery,
  'icon-brakes': IconBrakes,
  'icon-oil': IconOil,
  'icon-repair': IconRepair,
  'icon-routine': IconRoutine,
  'icon-suspension': IconSuspension,
  'icon-info': IconInfo,
  'icon-info-blue-solid': IconInfoBlueSolid,
  'icon-info-blue-regular': IconInfoBlueRegular,
  'alignment-red': AlignmentRed,
  'battery-red': BatteryRed,
  'brakes-red': BrakesRed,
  'oil-red': OilRed,
  'repair-red': RepairRed,
  'routine-red': RoutineRed,
  'suspension-red': SuspensionRed,
  'popular-red': PopularRed,
  'arrow-datepicker-right': ArrowDatepickerRight,
  'arrow-datepicker-left': ArrowDatepickerLeft,
  'arrow-red-right': ArrowRedRight,
  'arrow-bright-red-right': ArrowBrightRedRight,
  'arrow-red-down': ArrowRedDown,
  'arrow-gray-up': ArrowGrayUp,
  'arrow-red-up': ArrowRedUp,
  'arrow-down-black': ArrowDownBlack,
  'arrow-down-chartreuse': ArrowDownChartreuse,
  'arrow-down-light-blue': ArrowDownLightBlue,
  'arrow-up-light-blue': ArrowUpLightBlue,
  'arrow-gray-down': ArrowGrayDown,
  'icon-edit': IconEdit,
  'check-green': CheckedGreen,
  'check-gray': CheckGray,
  'check-white': CheckWhite,
  'caret-blue-down': CaretBlueDown,
  'caret-blue-right': CaretBlueRight,
  'arrow-blue-right': ArrowBluRight,
  'icon-filter': IconFilter,
  'user-icon-red': UserIconRed,
  'icon-filter-white': IconFilterWhite,
  'icon-circle-close': IconCircleClose,
  'icon-search': IconSearch,
  'search-chartreuse': SearchChartreuse,
  'search-white': SearchWhite,
  'search-blue': SearchBlue,
  'address-confirm-arrow': AddressConfirmArrow,
  'arrow-left-black': ArrowBlackLeft,
  'icon-street': IconStreet,
  'icon-terrain': IconTerrain,
  'default-amenity-icon': DefaultAmenity,
  'ellipsis-red': EllipsisRed,
  'accessibility-red': AccessibilityRed,
  'feedback-red': FeedbackRed,
  'empty-page': EmptyPage,
  'filled-up-arrow': FilledUpArrow,
  'filled-down-arrow': FilledDownArrow,
  'checkmark-box-filled-green': CheckmarkBoxFilledGreen,
  'checkmark-box-grey': CheckmarkBoxGrey,
  'big-o-tech': BigOTech,

  // TA icons
  'arrow-down': ArrowDown,
  'arrow-down-white': ArrowDownWhite,
  'arrow-right': ArrowRight,
  'arrow-right-white': ArrowRightWhite,
  'arrow-right-bright-red': ArrowRightBrightRed,
  'arrow-left-bright-red': ArrowLeftBrightRed,
  'arrow-down-bright-red': ArrowDownBrightRed,
  'arrow-up-bright-red': ArrowUpBrightRed,
  'arrow-up': ArrowUp,
  'arrow-up-fill': ArrowUpFill,
  'arrow-up-white': ArrowUpWhite,
  'caret-red-left': CaretRedLeft,
  'caret-red-right': CaretRedRight,
  'arrow-icon-full-black-down': ArrowDownFullBlack,
  'arrow-icon-full-white-down': ArrowDownFullWhite,
  'arrow-icon-full-black-up': ArrowUpFullBlack,
  'arrow-icon-full-white-up': ArrowUpFullWhite,
  'arrow-icon-full-red-down': ArrowDownFullRed,
  'arrow-icon-full-bright-red-down': ArrowDownFullBrightRed,
  'arrow-icon-full-red-up': ArrowUpFullRed,
  'arrow-icon-full-bright-red-up': ArrowUpFullBrightRed,
  'arrow-down-common-red': ArrowDownCommonRed,
  chat: Chat,
  'check-circle': CheckCircle,
  'menu-close': MenuClose,
  'menu-close-white': MenuCloseWhite,
  'close-icon-red': CloseIconRed,
  'dropdown-arrow': DropdownArrow,
  'dropdown-arrow-disabled': DropdownArrowDisabled,
  facebook: Facebook,
  instagram: Instagram,
  'icon-account': IconAccount,
  'icon-account-bright-red': IconAccountBrightRed,
  'icon-logout': IconLogout,
  'icon-logout-white': IconLogoutWhite,
  'master-card': MasterCard,
  'no-vehicle': NoVehicle,
  logo: Logo,
  location: Location,
  phone: Phone,
  'phone-white': PhoneWhite,
  'phone-black': PhoneBlack,
  'phone-blue': PhoneBlue,
  'phone-red': PhoneRed,
  video: Video,
  play: Play,
  pause: Pause,
  'pause-in-circle': PauseInCircle,
  'play-in-circle': PlayInCircle,
  search: Search,
  twitter: Twitter,
  'big-o-logo': BigOLogo,
  paypal: Paypal,
  car: Car,
  'car-example': CarExample,
  surface: Surface,
  'car-icon': CarIcon,
  petal: Petal,
  purse: Purse,
  calendar: Calendar,
  spinner: Spinner,
  'air-filter-change': AirFilterChange,
  'air-filter-change-red': AirFilterChangeRed,
  'alternator-stering-and-replacement': AlternatorSteringAndReplacement,
  'alternator-stering-and-replacement-red': AlternatorSteringAndReplacementRed,
  'ball-joints': BallJoints,
  'ball-joints-red': BallJointsRed,
  'battery-cable-replacement': BatteryCableReplacement,
  'battery-cable-replacement-red': BatteryCableReplacementRed,
  'battery-testing': BatteryTesting,
  'battery-testing-red': BatteryTestingRed,
  'belt-replacement': BeltReplacement,
  'belt-replacement-red': BeltReplacementRed,
  'brake-fluid-exchange': BrakeFluidExchange,
  'brake-fluid-exchange-red': BrakeFluidExchangeRed,
  'brake-inspection': BrakeInspection,
  'brake-inspection-red': BrakeInspectionRed,
  'brake-repair': BrakeRepair,
  'brake-repair-red': BrakeRepairRed,
  'cv-joints-and-cv-axles': CvJointsAndCvAxles,
  'cv-joints-and-cv-axles-red': CvJointsAndCvAxlesRed,
  'cabin-filter-change': CabinFilterChange,
  'cabin-filter-change-red': CabinFilterChangeRed,
  'cooling-stering-flush-and-fill': CoolingSteringFlushAndFill,
  'cooling-stering-flush-and-fill-red': CoolingSteringFlushAndFillRed,
  'differential-fluid-exchange': DifferentialFluidExchange,
  'differential-fluid-exchange-red': DifferentialFluidExchangeRed,
  'fuel-filter-change': FuelFilterChange,
  'fuel-filter-change-red': FuelFilterChangeRed,
  'fuel-system-cleaning': FuelSystemCleaning,
  'fuel-system-cleaning-red': FuelSystemCleaningRed,
  'headlights-and-taillights': HeadlightsAndTaillights,
  'headlights-and-taillights-red': HeadlightsAndTaillightsRed,
  'install-interstate-battery': InstallInterstateBattery,
  'install-interstate-battery-red': InstallInterstateBatteryRed,
  'install-interstate-mega-tron-ii-battery': InstallInterstateMegaTronIiBattery,
  'install-interstate-mega-tron-ii-battery-red': InstallInterstateMegaTronIiBatteryRed,
  'install-interstate-powerfast-battery': InstallInterstatePowerfastBattery,
  'install-interstate-powerfast-battery-red': InstallInterstatePowerfastBatteryRed,
  'oil-change': OilChange,
  'oil-change-red': OilChangeRed,
  'power-stering-fluid-exchange': PowerSteringFluidExchange,
  'power-stering-fluid-exchange-red': PowerSteringFluidExchangeRed,
  'rack-and-pinion': RackAndPinion,
  'rack-and-pinion-red': RackAndPinionRed,
  'radiant-and-coolant-hoses': RadiantAndCoolantHoses,
  'radiant-and-coolant-hoses-red': RadiantAndCoolantHosesRed,
  'scroll-down': ScrollDown,
  'shocks-shocks': ShocksShocks,
  'shocks-shocks-red': ShocksShocksRed,
  'snow-tire-installation': SnowTireInstallation,
  'snow-tire-installation-red': SnowTireInstallationRed,
  'special-order': SpecialOrder,
  'starter-stering-and-replacement': StarterSteringAndReplacement,
  'starter-stering-and-replacement-red': StarterSteringAndReplacementRed,
  'steering-stering-suspension-evaluation': SteeringSteringSuspensionEvaluation,
  'steering-stering-suspension-evaluation-red': SteeringSteringSuspensionEvaluationRed,
  'struts-struts': StrutsStruts,
  'struts-struts-red': StrutsStrutsRed,
  'tie-rods': TieRods,
  'tie-rods-red': TieRodsRed,
  'tire-mounting': TireMounting,
  'tire-mounting-red': TireMountingRed,
  'tire-pressure-check': TirePressureCheck,
  'tire-pressure-check-red': TirePressureCheckRed,
  'tire-repair': TireRepair,
  'tire-repair-red': TireRepairRed,
  'tire-rotation': TireRotation,
  'tire-rotation-red': TireRotationRed,
  'transmission-fluid-exchange': TransmissionFluidExchange,
  'transmission-fluid-exchange-red': TransmissionFluidExchangeRed,
  'used-tires': UsedTires,
  'used-tires-red': UsedTiresRed,
  'wheel-alignment': WheelAlignment,
  'wheel-alignment-red': WheelAlignmentRed,
  'wheel-balancing': WheelBalancing,
  'wheel-balancing-red': WheelBalancingRed,
  'wiper-blades': WiperBlades,
  'wiper-blades-red': WiperBladesRed,
  'tire-pressure-monitoring-system': TirePressureMonitoringSystem,
  'heart-black': HeartBlack,
  'heart-white': HeartWhite,
  'heart-red': HeartRed,
  'heart-red-filled': HeartRedFilled,
  'copy-link-desktop': ShareLinkDesktop,
  'copy-link-mobile': ShareLinkMobile,
  'garage-active-vehicle-placeholder': ActiveVehiclePlaceholder,
  'garage-vehicle-placeholder': VehiclePlaceholder,
  'speedometer-grey': SpeedometerGrey,
  'speedometer-blue': SpeedometerBlue,
  'edit-grey': EditGrey,
  'edit-red': EditRed,
  'ellipsis-grey': EllipsisGrey,
  'edit-black': EditBlack,
  'delete-black': DeleteBlack,
  'cart-red-secondary': CartRedSecondary,
  'tire-rotation-white': TireRotationWhite,
  'oil-change-white': OilChangeWhite,
  'tips-and-resources': TipsAndResources,
  mileage: Mileage,
  'main-edit-blue': MainEditBlue,
  'delete-blue': DeleteBlue,
  'delete-red': DeleteRed,
  'edit-blue': EditBlue,
  'edit-green': EditGreen,
  'tire-size': TireSize,
  license: License,
  'vin-number': VinNumber,
  'tire-rotation-secondary': TireRotationSecondary,
  'oil-change-secondary': OilChangeSecondary,
  'recomendation-success': RecomendationSuccess,
  'recomendation-medium': RecomendationMedium,
  'recomendation-warning': RecomendationWarning,
  'recomendation-danger': RecomendationDanger,
  'oil-change-ev': OilChangeEV,
  'alert-trianle-red': AlertTriangleRed,
  'alert-triangle-yellow': AlertTriangleYellow,
  'alert-triangle-black': AlertTriangleBlack,
  'alert-light-red': AlertLightRed,
  'disclaimer-blue': DisclaimerBlue,
  'service-history': ServiceHistory,
  'info-icon': InfoIcon,
  'vehicle-with-notebook': VehicleWithNotebook,
  'cloud-loading': CloudLoading,
  'fleet-certified-icon-gold': FleetCertifiedLogoGold,
  'fleet-certified-icon-green': FleetCertifiedLogoGreen,
  'tire-service-and-repair': RepairWhite,
  alignment: AlignmentWhite,
  batteries: BatteryWhite,
  brakes: BrakesWhite
}

const Icon = styled.img.attrs(({ alt, asset }) => ({
  alt: alt || alt === '' ? alt : asset
}))`
  vertical-align: ${props => props.valign || 'middle'};
  margin: ${props => props.margin || 0};
  float: ${props => props.float || 'none'};
  ${props => props.full && css`
    width: 100%;
  `}
  ${props => props.fit && css`
    max-width: 100%;
    max-height: 100%;
  `}
  ${props => props.animated && css`
    transition: all 0.3s;
  `}
  ${props => props.hide && css`
    /* stylelint-disable */
    ${props.hide}:hover & {
      display: none;
    }
  `}
  ${props => props.show && css`
    display: none;

    ${props.show}:hover & {
      display: inline-block;
    }
  `}
`

const LazyIcon = ({
  src,
  imgSrc,
  setImgRef,
  ...props
}) =>
  <Icon ref={ setImgRef } src={ src } data-src={ imgSrc } { ...props } />

LazyIcon.propTypes = {
  imgSrc: PropTypes.string,
  noLazyLoad: PropTypes.bool,
  setImgRef: PropTypes.func,
  src: PropTypes.string
}

const withSrc = withProps(({ noLazyLoad, ...props }) => ({
  src: noLazyLoad ? pickImgSrc(props, map) : pickSrc(props, map),
  imgSrc: noLazyLoad ? undefined : pickImgSrc(props, map)
}))

export default styled(compose(
  imgLazyLoad,
  withSrc
)(LazyIcon))`
  ${props => props.desktopOnly && Mixin.desktopOnly()};
  ${props => props.mobileOnly && Mixin.mobileOnly()};
`
